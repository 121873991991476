<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchInvoices(invoiceFilterParams)">
        {{ $t('general.invoices') }}
      </v-tab>

      <v-tab-item>
        <div class="d-flex justify-space-between pr-4">
          <div class="text-subtitle-1 font-weight-medium py-2 px-4">
            <template v-if="totalInvoicesSum">
              <span class="mr-2">{{ $t('general.total') }}:</span>
              <br v-if="$vuetify.breakpoint.xsOnly" />
              <span>{{ totalInvoicesSum | currency }}</span>
            </template>
          </div>

          <div class="d-flex align-center">
            <InvoiceFilter
              :filter-params="invoiceFilterParams"
              @change="$router.push({ name: 'invoices', query: $event })"
              @reset="resetFilters"
            />

            <v-tooltip v-if="$can(['invoice.create'])" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="$router.push({ name: 'updateInvoiceCounter' })">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('invoices.general.renew_last_invoice_no_counter') }}
              </span>
            </v-tooltip>
          </div>
        </div>

        <InvoiceTable
          :items="invoices"
          :loading="$store.getters.loading['get:api/finances/invoices']"
          :pagination="invoicePagination"
          @click:row="viewInvoice"
          @view-invoice="viewInvoice"
          @mark-as-paid="markInvoiceAsPaid"
          @delete="deleteInvoice"
          @update:page="onPageChange"
        />

        <InvoicesChart :statistics="invoiceSalesStatistics" @filter:paid="onFilterPaid" />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      v-if="$can(['invoices.create']) && !$isClient()"
      @click="$router.push({ name: 'createInvoice' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import InvoiceTable from '@/components/tables/InvoiceTable';
import InvoiceFilter from '@/components/filters/InvoiceFilter';
import InvoicesChart from '@/components/InvoicesChart';
import { getDefaultInvoiceFilterParams } from '@/store/modules/invoices-module';
import { getSanitizedFilterParams } from '@/util/filter-params';

export default {
  name: 'Invoices',

  components: {
    InvoicesChart,
    BasePrimaryActionButton,
    InvoiceTable,
    InvoiceFilter,
  },

  computed: {
    ...mapState('invoices', [
      'invoices',
      'invoiceSalesStatistics',
      'invoicePagination',
      'invoiceFilterParams',
      'totalInvoicesSum',
    ]),
  },

  created() {
    this.fetchInvoices(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.invoiceFilterParams) !== JSON.stringify(params) &&
      to.name === 'invoices'
    ) {
      this.fetchInvoices(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('invoices', ['fetchInvoices', 'markInvoiceAsPaid', 'deleteInvoice']),

    viewInvoice(invoice) {
      this.$router.push({ name: 'invoice', params: { invoiceId: invoice.id } });
    },

    onPageChange(page) {
      const query = { ...this.invoiceFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'invoices', query });
    },

    onFilterPaid(value) {
      const query = { ...this.invoiceFilterParams, is_payment_received: value };
      if (value === null) {
        delete query.is_payment_received;
      }
      this.$router.push({ name: 'invoices', query });
    },

    resetFilters() {
      this.$router.push({ name: 'invoices', query: getDefaultInvoiceFilterParams() });
    },
  },
};
</script>
