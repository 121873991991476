var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.per_page || 50],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"page":_vm.pagination.current_page || 1,"server-items-length":_vm.pagination.total || -1,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
  var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1 + (_vm.pagination.current_page - 1) * _vm.pagination.per_page)+". ")]}},{key:"item.invoice_no",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.series)+_vm._s(item.number)+" ")]}},{key:"item.buyer",fn:function(ref){
  var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_vm._v(" "+_vm._s(item.buyer_name)+" ")]:[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(item.buyer_name)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.due_date)+" ")]),_c('v-col',{attrs:{"cols":"8"}},[(item.is_payment_received)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.$t('general.actions.paid'))+" "+_vm._s(item.payment_received_at)+" ")]):_vm._e(),(item.is_invalidated)?_c('span',{staticClass:"alert-text"},[_vm._v(" "+_vm._s(_vm.$t('invoices.general.invalid'))+" ")]):_vm._e(),(!item.is_payment_received && !item.is_invalidated)?_c('span',[_c('span',{class:{
                'alert-text': item.due_date < _vm.currentDate && !item.is_payment_received,
              }},[_vm._v(" "+_vm._s(_vm.$t('invoices.general.not_paid'))+" ")])]):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])],1)]]}},{key:"item.total",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.due_date",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.due_date))])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.is_payment_received)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.$t('general.actions.paid'))+" "+_vm._s(item.payment_received_at)+" ")]):_vm._e(),(item.is_invalidated)?_c('span',{staticClass:"alert-text"},[_vm._v(" "+_vm._s(_vm.$t('invoices.general.invalid'))+" ")]):_vm._e(),(!item.is_payment_received && !item.is_invalidated)?_c('span',[_c('span',{class:{
          'alert-text': item.due_date < _vm.currentDate && !item.is_payment_received,
        }},[_vm._v(" "+_vm._s(_vm.$t('invoices.general.not_paid'))+" ")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.invoice_no",fn:function(){return [_vm._v(" "+_vm._s(item.series)+_vm._s(item.number)+" ")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }