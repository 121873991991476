<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page || 50],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :page="pagination.current_page || 1"
    :server-items-length="pagination.total || -1"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    :item-class="() => 'clickable'"
    disable-sort
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.no="{ index }">
      {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}.
    </template>

    <template v-slot:item.invoice_no="{ item }"> {{ item.series }}{{ item.number }} </template>

    <template v-slot:item.buyer="{ item }">
      <template v-if="$vuetify.breakpoint.smAndUp">
        {{ item.buyer_name }}
      </template>
      <template v-else>
        <v-row align="center" no-gutters>
          <v-col cols="8">
            {{ item.buyer_name }}
          </v-col>
          <v-col class="text-right" cols="4">
            {{ item.due_date }}
          </v-col>
          <v-col cols="8">
            <span v-if="item.is_payment_received" class="text-success">
              {{ $t('general.actions.paid') }} {{ item.payment_received_at }}
            </span>
            <span v-if="item.is_invalidated" class="alert-text">
              {{ $t('invoices.general.invalid') }}
            </span>
            <span v-if="!item.is_payment_received && !item.is_invalidated">
              <span
                :class="{
                  'alert-text': item.due_date < currentDate && !item.is_payment_received,
                }"
              >
                {{ $t('invoices.general.not_paid') }}
              </span>
            </span>
          </v-col>
          <v-col class="text-right" cols="4">
            {{ item.total | currency }}
          </v-col>
        </v-row>
      </template>
    </template>

    <template v-slot:item.total="{ item }">
      {{ item.total | currency }}
    </template>

    <template v-slot:item.due_date="{ item }">
      <div class="text-no-wrap">{{ item.due_date }}</div>
    </template>

    <template v-slot:item.status="{ item }">
      <span v-if="item.is_payment_received" class="text-success">
        {{ $t('general.actions.paid') }} {{ item.payment_received_at }}
      </span>
      <span v-if="item.is_invalidated" class="alert-text">
        {{ $t('invoices.general.invalid') }}
      </span>
      <span v-if="!item.is_payment_received && !item.is_invalidated">
        <span
          :class="{
            'alert-text': item.due_date < currentDate && !item.is_payment_received,
          }"
        >
          {{ $t('invoices.general.not_paid') }}
        </span>
      </span>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.invoice_no> {{ item.series }}{{ item.number }} </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import { format } from 'date-fns';

export default {
  name: 'ContactTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentDate: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: this.$t('general.no'), value: 'no', hidden: 'smAndDown', hideInExpandedRow: true },
        { text: this.$t('invoices.labels.invoice_no'), value: 'invoice_no', hidden: 'smAndDown' },
        { text: this.$t('invoices.labels.buyer'), value: 'buyer' },
        { text: this.$t('general.sum'), value: 'total', hidden: 'xsOnly', hideInExpandedRow: true },
        {
          text: this.$t('general.date_and_time.valid_until'),
          value: 'due_date',
          hidden: 'xsOnly',
          hideInExpandedRow: true,
        },
        {
          text: this.$t('general.status'),
          value: 'status',
          hidden: 'xsOnly',
          hideInExpandedRow: true,
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];

      return headers;
    },
  },

  methods: {
    getRowActions(item) {
      const actions = [];

      if (this.$vuetify.breakpoint.smAndDown) {
        actions.push({
          key: 'viewInvoice',
          callback: (p) => this.$emit('view-invoice', p),
          label: this.$t('general.controls.preview'),
          icon: 'visibility',
        });
      }

      if (!this.$isClient() && !item.is_payment_received) {
        actions.push({
          callback: (p) => this.$emit('mark-as-paid', p),
          label: this.$t('invoices.controls.mark_as_paid'),
          loading:
            this.$store.getters.loading[`put:api/finances/invoices/${item.id}/payment-received`],
          icon: 'check',
        });
      }

      return actions;
    },
  },
};
</script>
